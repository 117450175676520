import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TokenService } from '../service_partagé/token.service';

@Injectable({
  providedIn: 'root'
})

export class AuthStateService {

  private userState = new BehaviorSubject<boolean>(this.token.isLoggedIn());
  userRole = new BehaviorSubject<string>(this.token.isRoleName());
  userAuthState = this.userState.asObservable();
  userAuthRole = this.userRole.asObservable();

  constructor(
    public token: TokenService
  ) { }

  // tslint:disable-next-line:typedef
  setAuthState(value: boolean) {
    this.userState.next(value);
  }
  // tslint:disable-next-line:typedef
  setAuthRole(value: string) {
    this.userRole.next(value);
  }

}
