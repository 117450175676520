import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../auth.service';
import {TokenService} from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthAgreementGuardGuard implements CanActivate {
  constructor(private token: TokenService, private router: Router) {}

  canActivate(): boolean {
    const AcceptAgreement = this.token.isAcceptAgreement();
    const user = this.token.getDataUser();
    if (  user && !AcceptAgreement) {
      this.router.navigate(['/user-agreement']);
      return false;
    }
    return true;
  }
}
