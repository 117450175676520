import { TokenService } from 'src/app/services/service_partagé/token.service';
import { Injectable } from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import * as moment from 'moment';
import { Router } from '@angular/router';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class FunctionServiceService {
  constructor(private http: HttpClient,  private tokenService: TokenService, private router: Router) {
  }
  url = environment;

  notify = [];

  updat_Avatar() {
    return JSON.parse(localStorage.getItem('avatar'));
  }
  admin_add(data: any): Observable<any> {
    // @ts-ignore
    return this.http.post<[Iuser]>(this.url.API_URL + '/api/user' , data);
  }
  work_time_calcul(Time1, Time2, unpaid = 0) {
        if (Time1 && Time2) {
            // Determine the format based on the presence of a space in the input
            const format1 = Time1.includes(' ') ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss';
            const format2 = Time2.includes(' ') ? 'YYYY-MM-DD HH:mm:ss' : 'HH:mm:ss';

            const Date1 = moment(Time1, format1);
            const Date2 = moment(Time2, format2);

            // Handle the case when Time2 is before Time1
            if (Date2.isBefore(Date1)) {
                // Assuming Time2 is on the next day
                Date2.add(1, 'day');
            }

            let totalHours = moment.duration(Date2.diff(Date1)).asHours();

            // Convert unpaid minutes to hours and subtract from total hours
            const unpaidHours = unpaid / 60;
            totalHours -= unpaidHours;

            return totalHours.toFixed(2);
        } else {
            return null;
        }
    }
  resetQurey(table) {
    for (let i = 0; i < table.length; i++) {
      table[i].value = '';
    }
    return table;
  }
  generatQuery(table) {
    let query = '';
    let counter = 0;
    for (let i = 0; i < table.length; i++) {
        // tslint:disable-next-line:triple-equals

      if (table[i].value != '') {
        counter = counter + 1;
          // tslint:disable-next-line:triple-equals
        if (counter == 1) {
          query = query + '?';
        } else {
          query = query + '&';
        }
        query = query + table[i].element + '=' + table[i].value;
      }
    }

    return query;
  }
  showNotification(result , typeNotif , timer = 200, fromP = 'top' , align = 'center') {
    const type = ['', 'info', 'success', 'warning', 'danger', 'rose', 'primary'];
    if (this.notify.length > 1) {
      $.notifyClose() ;
      this.notify = [];
    }
    this.notify.push(
      $.notify({
          icon: 'notifications',
          message: result
      }, {
          type: typeNotif,
          timer: timer,
          placement: {
              from: fromP,
              align: align
          },
          z_index: 1500,
          allow_dismiss: true,
          newest_on_top: true,
          template: '<div data-notify="container" class="col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
            '<button mat-raised-button btn-sm type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
            '<i class="material-icons" data-notify="icon">notifications</i> ' +
            '<span data-notify="title">{1}</span> ' +
            '<span data-notify="message">{2}</span>' +
            '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
            '</div>' +
            '<a href="{3}" target="{4}" data-notify="url"></a>' +
          '</div>'
      })
    );
  }
  // Next section is for  get address from  service google
  getLong(components: any, name: string) {
    const component = this.getComponent(components, name);
    // @ts-ignore
    return component && component.long_name;
  }
  getComponent(components: any, name: string) {
      // @ts-ignore
      return Object.values(components).filter(component => component.types[0] === name)[0];
  }
  handleAddressChange(addressInput: any) {
      const address = [];
      address['address'] =   addressInput.formatted_address ;
      address['street_number'] =   this.getLong(addressInput.address_components , 'street_number') ;
      address['route'] =  this.getLong(addressInput.address_components , 'route') ;
      address['locality'] = this.getLong(addressInput.address_components , 'locality');
      address['administrative_area_level_1'] = this.getLong(addressInput.address_components , 'administrative_area_level_1');
      address['country'] = this.getLong(addressInput.address_components , 'country');
      address['postal_code'] = this.getLong(addressInput.address_components , 'postal_code');
      address['Latitude'] =   addressInput.geometry.location.lat();
      address['Longitude'] =   addressInput.geometry.location.lng();
      return address ;
  }
  DaysOfWeek() {
    return   [
      {index: 1, mini: 'Lun', value: 'Lundi', day: 'Monday', is_selected: false},
      {index: 2, mini: 'Mar', value: 'Mardi', day: 'Tuesday', is_selected: false},
      {index: 3, mini: 'Mer', value: 'Mercredi', day: 'Wednesday', is_selected: false},
      {index: 4, mini: 'Jeu', value: 'Jeudi', day: 'Thursday', is_selected: false},
      {index: 5, mini: 'Ven', value: 'Vendredi', day: 'Friday', is_selected: false},
      {index: 6, mini: 'Sam', value: 'Samedi', day: 'Saturday', is_selected: false},
      {index: 0, mini: 'Dim', value: 'Dimanche', day: 'Sunday', is_selected: false},
    ];
  }
  add_dayFrench(table) {
    for (let i = 0; i < table.length ; i++) {
        const res = this.DaysOfWeek().find(elm => elm.day === table[i].day);
        Object.assign(table[i], {mini: res?.mini}, {value: res?.value}, {index: res?.index});
    }
    return table;
  }

  routeTo(route, homePage= false, blank= false) {
    console.log(route, homePage, blank);
    if (homePage) {
      if (blank == true) {
        this.router.navigate([]).then(result => {  window.open( '#/' + route, '_blank'); });
      } else {
        this.router.navigateByUrl(route);
      }
    } else {
      // const role =
      // let role = 'Super-Admin'
      let addToRout = '';
      if (this.tokenService.isRole('Super-Admin')) {
        addToRout = '/superadmin/';
      } else if (this.tokenService.isRole('admin')) {
        addToRout = '/admin/';
      } else if (this.tokenService.isRole('employee')) {
        addToRout = '';
      }
      if (blank == true) {
        this.router.navigate([]).then(result => {  window.open( '#' + addToRout + route, '_blank'); });
      } else {
        this.router.navigateByUrl(addToRout + route);
      }

    }
  }

  getRoute(route: string): string {
        let addToRoute = '';
        if (this.tokenService.isRole('Super-Admin')) {
            addToRoute = '/superadmin/';
        } else if (this.tokenService.isRole('admin')) {
            addToRoute = '/admin/';
        } else if (this.tokenService.isRole('employee')) {
            addToRoute = '';
        }
        return addToRoute + route;
    }


    timeAgo(input: Date | string): string {
      const date = input instanceof Date ? input : new Date(input);
      const now = new Date();
      const difference = now.getTime() - date.getTime();


        const minutes = Math.floor(difference / 60000);
        const hours = Math.floor(difference / 3600000);
        const days = Math.floor(difference / (3600000 * 24));

        // if (minutes < 1) { return 'miantenat'; }
        if (minutes < 60) { return `${minutes} mn`; }
        if (hours < 24) { return `${hours} h`; }
        return `${days} j`;
    }
    // Convert a base64 string to a Blob
  base64ToBlob(base64, mimeType) {
        const byteCharacters = atob(base64.split(',')[1]);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        return new Blob(byteArrays, { type: mimeType });
    }

}
