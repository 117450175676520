import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree ,  Router} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from './auth-state.service';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthLoginGuard implements CanActivate {
  isSignedIn: boolean;

  constructor(private tokenService: TokenService, private router: Router,public auth: AuthStateService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin();
  }
  /*
  checkLogin(): true|UrlTree {
    if (localStorage.getItem('auth_token')) { return this.router.parseUrl(this.token.pageaccuiel());  }
    return true;
  }*/
  checkLogin(): true|UrlTree {
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
    // tslint:disable-next-line:max-line-length
    if (this.isSignedIn && this.tokenService.isemailVerified() ) { return true;  }
    return this.router.parseUrl(this.tokenService.pageaccuiel());
  }
}
