<nav *ngIf="showNavbar" class="navbar navbar-expand-lg bg-primary navbar-transparent navbar-absolute" color-on-scroll="500">
    <div class="container">
        <div class="navbar-wrapper">
            <a class="navbar-brand d-none d-sm-none d-md-block" (click)="openPage('dashboard')">Agence de placemenet MostPro</a>
            <a class="navbar-brand d-block d-sm-block d-md-none" (click)="openPage('dashboard')">Mostpro</a>
        </div>
        <button mat-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
            <span class="sr-only">Toggle navigation</span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
            <span class="navbar-toggler-icon icon-bar"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end">
            <ul class="navbar-nav">
                <li class="nav-item" routerLinkActive="active">
                    <a class="nav-link" (click)="openPage('login')">
                        <i class="material-icons">fingerprint</i> Se Connecter
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="openPage('lock')" [routerLink]="['']">
                        <i class="material-icons">lock_open</i> Mot de passe oublié ?
                    </a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<router-outlet></router-outlet>
