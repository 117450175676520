import { Injectable } from '@angular/core';
import { TokenService } from 'src/app/services/service_partagé/token.service';
import {environment} from '../../../environments/environment';
import Pusher from 'pusher-js';

@Injectable({
  providedIn: 'root'
})
export class PusherService {
  private pusher: any;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private TokenService: TokenService) {
    this.initializePusher();
  }

  private initializePusher() {
    this.pusher = new Pusher(environment.PUSHER_APP_KEY, {
      cluster: environment.PUSHER_APP_CLUSTER,
      authEndpoint: environment.API_URL + '/broadcasting/auth',
      auth: {
        headers: {
          'Authorization': 'Bearer ' + this.TokenService.getToken(),
          Accept: 'application/json'
        }
      }
    });


  }

  public subscribe(channelName: string) {
    return this.pusher.subscribe(channelName);
  }
}
