import { Routes } from '@angular/router';

import { AuthAdminGuard } from './services/service_partagé/auth-admin.guard';
import { AuthEmployeeGuard } from './services/service_partagé/auth-employee.guard';
import { AuthSuperadminGuard } from './services/service_partagé/auth-superadmin.guard';
import { AuthLayoutComponent } from './super-admin/layouts/auth/auth-layout.component';
import { AuthLogoutGuard } from './services/service_partagé/auth-logout.guard';
import { AuthLoginGuard } from './services/service_partagé/auth-login.guard';
import { AuthAgreementGuardGuard } from './services/service_partagé/Guard/auth-agreement-guard.guard';

export const AppRoutes: Routes = [
    {
     path: '',
     redirectTo: 'login',
    },
    {
        path: '*',
        redirectTo: 'login',
    },
    {
       path: '',
       component: AuthLayoutComponent,
       children: [{
       path: '',
           loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
       }, ]
   },
   {
        path: 'superadmin',
        canActivate : [AuthLoginGuard, AuthSuperadminGuard , AuthAgreementGuardGuard],
        children: [
            {
            path: '',
            loadChildren: () => import('./super-admin/super-admin.module').then(m => m.SuperAdminModule)
            },
        ]
    },
   {
       path: 'admin',
       canActivate : [AuthLoginGuard, AuthAdminGuard , AuthAgreementGuardGuard],
       children: [
           {
           path: '',
           loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
           },
       ]
   },
   {
    path: '',
    canActivate : [AuthLoginGuard, AuthEmployeeGuard , AuthAgreementGuardGuard],
    children: [
        {
        path: '',
        loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
        },
    ]
},
];







/*
export const AppRoutes: Routes = [
     {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule)
    } ,
    {
      path: '',
      redirectTo: 'login',
    }, {
      path: '',
      component: AdminLayoutComponent,
      canActivate: [AuthSuperadminGuard],
      children: [
        {
            path: '',
            loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
        },{
            path: 'centreTri',
            loadChildren: () => import('./centre-tris/centre-tris.module').then(m => m.CentreTrisModule)
        }, {
            path: 'travailleursAcc',
            loadChildren: () => import('./travailleurs/travailleurs.module').then(m => m.TravailleursModule)
        }, {
            path: 'planification',
            loadChildren: () => import('./planification/planification.module').then(m => m.PlanificationModule)
        }, {
            path: 'paiement',
            loadChildren: () => import('./paiement/paiement.module').then(m => m.PaiementModule)
        }, {
            path: 'parametre',
            loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
        },
        {
            path: 'job-offers',
            loadChildren: () => import('./job-offers/job-offers.module').then(m => m.JobOffersModule )
        },
  ]},
   {
        path: '',
        component: AuthLayoutComponent,
        canActivate : [AuthLogoutGuard],
        children: [{
        path: '',
            loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule)
        }, ]
    },
    {
        path: '',
        canActivate: [AuthLoginGuard],
        component: AdminLayoutComponent,
        children: [{
            path: 'profile',
            canActivateChild: [AuthEmployeeGuard],
            loadChildren: () => import('./profil/profil.module').then(m => m.ProfilModule),
        },
        {
            path: 'profile/edit', component: EditProfilComponent
        }]
    },
    {
        path: 'employe',
        component: LayoutEmployeeComponent,
        canActivate : [AuthEmployeeGuard],
        children: [
            {
            path: '',
            loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule)
            },
        ]
    },
    {
        path: 'admin',
        component: LayoutAdminComponent,
        canActivate : [AuthAdminGuard],
        children: [
            {
            path: '',
            loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
            },
        ]
    },
];
*/
