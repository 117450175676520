import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthStateService } from './auth-state.service';
import { TokenService } from './token.service';
import { FunctionServiceService } from './BE_Service/function-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthSuperadminGuard implements CanActivate, CanActivateChild {
  isSignedIn: boolean;
  isRole: string;
  constructor(private TokenService: TokenService,private router: Router, public auth: AuthStateService,public BESharedService: FunctionServiceService) {}
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin();
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route,state);
  }
  checkLogin(): true|UrlTree {
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
    this.auth.userAuthRole.subscribe(val => {
      this.isRole = val;
    });
    // tslint:disable-next-line:max-line-length
    if (this.isSignedIn && this.isRole === 'Super-Admin' &&  this.TokenService.isemailVerified()  ){
       return true;  
    }else{
      this.BESharedService.showNotification("vous n'êtes pas autorisé",'warning')
      //this.TokenService.LogOut()
      return this.router.parseUrl('/login');
    }
  }
}
