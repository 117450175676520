export const environment = {
  production: true,
  API_URL: 'https://mostpro-back-end-prod.herokuapp.com',
  PUSHER_APP_KEY: '93aa7f8066090dd99f0f',
  PUSHER_APP_CLUSTER: 'us2',
  HideNavTemp: true,
  callendarType: 'users',

  get_API_URL()  {
    return this.API_URL;
  }
};
