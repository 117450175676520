import { Injectable } from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse} from '@angular/common/http';
import { TokenService } from '../service_partagé/token.service';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { FunctionServiceService } from './BE_Service/function-service.service';

@Injectable()

export class AuthInterceptor implements HttpInterceptor {
  constructor(private tokenService: TokenService, private router: Router, public BESharedService: FunctionServiceService, private token: TokenService) { }

  // tslint:disable-next-line:typedef
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.tokenService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + accessToken
      }
    });
    return next.handle(req).pipe( tap(() => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 403 ) {
            this.BESharedService.showNotification("Vous avez pas l'autorisation", 'warning');                
          }
          if (err.status === 401  || err.status === 402 ) {
            this.token.removeToken();
            this.BESharedService.routeTo('login',true);
          }
        }
      }));
  }
}
