import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {User} from './Models/user';
import {ServicePartageService} from './service-partage.service';
import {ConnectionBackEndService} from './connection-back-end.service';
import {catchError, retry} from 'rxjs/operators';
import {environment} from 'src/environments/environment';




@Injectable({
  providedIn: 'root'
})

export class AuthService {
  url = environment ;
  constructor(private http: HttpClient ) { }

  register(user: User): Observable<any> {
    return this.http.post(this.url.get_API_URL() + '/api/signup', user);
  }

  // Login
  signin(user: User): Observable<any> {
    return this.http.post<any>(this.url.get_API_URL() + '/api/login', user);
  }

  acceptUserAgreement(): Observable<any> {
    return this.http.post<any>(this.url.get_API_URL() + '/api/accept-user-agreement', null);
  }
  // Access user profile
  profileUser(): Observable<any> {
    return this.http.get(this.url.get_API_URL() + '/api/user');
  }

  logout(token): Observable<any> {
    // @ts-ignore
    return this.http.post(this.url.get_API_URL() + '/api/logout', token);
  }
  forgetPassword(data: any): Observable<any> {
    return this.http.post<any>(this.url.get_API_URL() + '/api/forgot-password', data);
  }
  resetPassword(data: any): Observable<any> {
    return this.http.post<any>(this.url.get_API_URL() + '/api/reset-password', data);
  }
  ChangePassword(data: any): Observable<any> {
    return this.http.post<any>(this.url.get_API_URL() + '/api/change_password', data);
  }
}
