import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenService} from './token.service';
import { AuthStateService } from './auth-state.service';
import { FunctionServiceService } from './BE_Service/function-service.service';


@Injectable({
  providedIn: 'root'
})
export class AuthEmployeeGuard implements CanActivate, CanActivateChild {
  isSignedIn: boolean;
  isRole: string;
  // tslint:disable-next-line:max-line-length
  constructor(private TokenService: TokenService, private router: Router , public auth: AuthStateService, public BESharedService: FunctionServiceService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.checkLogin();
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.canActivate(route, state);
  }
  checkLogin(): true|UrlTree {
    this.auth.userAuthState.subscribe(val => {
      this.isSignedIn = val;
    });
    this.auth.userAuthRole.subscribe(val => {
      this.isRole = val;
    });

    if (this.isSignedIn && (this.isRole === 'employer') &&  this.TokenService.isemailVerified()  ) { return true; alert(true)  } else {
      this.BESharedService.showNotification('vous n\'êtes pas autorisé', 'warning');
      // this.TokenService.LogOut()
      return this.router.parseUrl('/login');
    }
  }
}
